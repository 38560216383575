/* Styles for elements dynamically rendered inside the details input of issue request form */

#details a {
  color: rgb(100, 153, 255);
}

#details ul {
  margin: 1.5rem 0 1.5rem 1rem;
  list-style: disc;
}

#details pre {
  overflow-x: auto;
  overflow-y: visible;
  font-size: 0.9em;
}

/* Priority Radio's */
input[type="radio"] {
  opacity: 0;
}

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"]:checked + label span {
  background-color: #004fef;
}

input[type="radio"]:checked:focus + label span {
  outline: 2px solid #bbb;
}

html.dark input {
  color-scheme: dark;
}
