@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .white-btn {
    @apply p-4 text-[16px] bg-white hover:bg-gray-200 text-black border border-dark rounded text-center dark:hover:bg-slate-600 dark:bg-slate-700 dark:text-gray-200 disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-500 dark:disabled:bg-slate-700 dark:disabled:text-gray-400 dark:disabled:border-gray-500 active:shadow-[inset_0_1px_3px#888] dark:active:shadow-[inset_0_2px_3px#000];
  }
  .black-shadow {
    @apply hover:shadow-[-8px_8px_#000];
  }
  .border-normal {
    @apply border-gray-200 dark:border-slate-600;
  }
  .border-dark {
    @apply border-gray-400 dark:border-slate-900;
  }
}

html,
body {
  background-color: #f2f2f2 !important;
  min-height: 100%;
  height: 100%;
  position: relative;
  overscroll-behavior: none;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
  margin: 0;
}

.homepage-hero-heading {
  font-size: clamp(10px, 5 * (1vw + 1vh) / 2, 100px);
}

.homepage-hero-text {
  font-size: clamp(10px, 3 * (1vw + 1vh) / 2, 40px);
}

/* Scrollbar styles */
.thin-scrollbar {
  scrollbar-width: thin;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.thin-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}
.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #eee;
  border: 1px solid #5e5e5e;
}
.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #7a7a7a;
}
/* Dark */
html.dark .thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #101828;
  border: 1px solid #000;
}
html.dark .thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #cbcbcb;
}
/* Main HTML Scrollbar */
html {
  scrollbar-width: thin;
  scrollbar-color: #aaa #f2f2f2;
}
html.dark {
  scrollbar-color: #475569 #1e293b;
}
html::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
html::-webkit-scrollbar-track {
  background: transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}
html::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #d3d3d3;
  border: 1px solid #5e5e5e;
}
html::-webkit-scrollbar-thumb:hover {
  background-color: #7a7a7a;
}
/* Dark */
html.dark::-webkit-scrollbar-track {
  background: #1e283b;
  background-clip: content-box; /* THIS IS IMPORTANT */
}
html.dark::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #475569;
  border: 1px solid #000;
}
html.dark::-webkit-scrollbar-thumb:hover {
  background-color: #cbcbcb;
}
/* End Scrollbar styles  */

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
select::-ms-expand {
  display: none;
}
