.gantt-chart input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #3350e8;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: 0.2s ease;
}
.gantt-chart input[type='range']::-webkit-slider-thumb:hover {
  border: 1px solid white;
}

.gantt-chart .gantt-bar .tooltip::before {
  z-index: 10;
}
